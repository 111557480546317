var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-user-plus")]),_vm._v(" Share ")],1)]}}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',[_c('v-toolbar',{staticStyle:{"margin-bottom":"1em"}},[_c('v-toolbar-title',[_vm._v("Share this event check-in")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.active = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),_c('v-card-text',[_c('p',[_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":function($event){_vm.showExplanation = !_vm.showExplanation}}},[_vm._v(_vm._s(_vm.showExplanation ? 'Hide' : 'Show')+" Explanation of the roles")])],1),(_vm.showExplanation)?_c('ul',[_c('li',[_vm._v("Admin: Can manage share settings, view the full list of attendees, and scan attendees")]),_c('li',[_vm._v("Reviewer: Can view the full list of attendees and scan attendees")]),_c('li',[_vm._v("Scanner: Can scan attendees and can see the last few that they have scanned but cannot view the full list")])]):_vm._e(),_c('v-list',{staticStyle:{"height":"250px","overflow":"auto"},attrs:{"elevation":"2"}},_vm._l((_vm.share),function(ref,index){
var photo = ref.photo;
var name = ref.name;
var role = ref.role;
var userRole = ref.user;
return _c('v-list-item',{key:'share-' + index},[_c('v-list-item-avatar',[(photo)?_c('v-img',{attrs:{"src":photo}}):_c('v-avatar',{attrs:{"color":"accent"}},[_vm._v(_vm._s(name.substr(0, 1)))])],1),_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-action',{staticStyle:{"min-width":"125px"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(role)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.updateRole(index, 'Admin')}}},[_c('v-list-item-title',[_vm._v("Admin")]),_c('v-list-item-avatar',[(role === 'Admin')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("far fa-check")]):_vm._e()],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateRole(index, 'Reviewer')}}},[_c('v-list-item-title',[_vm._v("Reviewer")]),_c('v-list-item-avatar',[(role === 'Reviewer')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("far fa-check")]):_vm._e()],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateRole(index, 'Scanner')}}},[_c('v-list-item-title',[_vm._v("Scanner")]),_c('v-list-item-avatar',[(role === 'Scanner')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("far fa-check")]):_vm._e()],1)],1),_c('v-divider'),(userRole !== _vm.user.directoryId)?_c('v-list-item',{on:{"click":function($event){return _vm.removeUser(userRole)}}},[_c('v-list-item-title',[_vm._v("Remove")])],1):_vm._e()],1)],1)],1)],1)}),1),_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.userSearchResults,"search-input":_vm.userSearch,"cache-items":false,"label":"Search for Users to Add","item-text":"_id","item-value":"_id","return-object":"","no-filter":"","outlined":""},on:{"update:searchInput":function($event){_vm.userSearch=$event},"update:search-input":function($event){_vm.userSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":item.photo}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item.name.last + ', ' + item.name.first))])]}}]),model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.active = false}}},[_vm._v("Done")]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.message))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }