<template>
  <v-dialog width="600" v-model="active">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text>
        <v-icon left>fal fa-user-plus</v-icon>
        Share
      </v-btn>
    </template>
    <v-card>
      <v-toolbar style="margin-bottom:1em">
        <v-toolbar-title>Share this event check-in</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="active = false">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <p>
          <v-btn outlined small @click="showExplanation = !showExplanation">{{ showExplanation ? 'Hide' : 'Show' }} Explanation of the roles</v-btn>
        </p>
        <ul v-if="showExplanation">
          <li>Admin: Can manage share settings, view the full list of attendees, and scan attendees</li>
          <li>Reviewer: Can view the full list of attendees and scan attendees</li>
          <li>Scanner: Can scan attendees and can see the last few that they have scanned but cannot view the full list</li>
        </ul>
        <v-list elevation="2" style="height:250px;overflow:auto">
          <v-list-item v-for="({ photo, name, role, user: userRole }, index) in share" :key="'share-' + index">
            <v-list-item-avatar>
              <v-img v-if="photo" :src="photo"></v-img>
              <v-avatar v-else color="accent">{{ name.substr(0, 1) }}</v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>{{ name }}</v-list-item-title>
            <v-list-item-action style="min-width:125px">
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text>
                    {{ role }}
                    <v-icon right>fal fa-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="updateRole(index, 'Admin')">
                    <v-list-item-title>Admin</v-list-item-title>
                    <v-list-item-avatar>
                      <v-icon v-if="role === 'Admin'" color="success">far fa-check</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-list-item @click="updateRole(index, 'Reviewer')">
                    <v-list-item-title>Reviewer</v-list-item-title>
                    <v-list-item-avatar>
                      <v-icon v-if="role === 'Reviewer'" color="success">far fa-check</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-list-item @click="updateRole(index, 'Scanner')">
                    <v-list-item-title>Scanner</v-list-item-title>
                    <v-list-item-avatar>
                      <v-icon v-if="role === 'Scanner'" color="success">far fa-check</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item v-if="userRole !== user.directoryId" @click="removeUser(userRole)">
                    <v-list-item-title>Remove</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-autocomplete v-model="selectedUser" :items="userSearchResults" :search-input.sync="userSearch" :cache-items="false" label="Search for Users to Add" class="mt-4" item-text="_id" item-value="_id" return-object no-filter outlined>
          <template v-slot:item="{ item }">
            <v-list-item-avatar>
              <v-img :src="item.photo"></v-img>
            </v-list-item-avatar>
            <v-list-item-title>{{ item.name.last + ', ' + item.name.first }}</v-list-item-title>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Done</v-btn>
        <v-spacer></v-spacer>
        <span>{{ message }}</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch, computed } from '@vue/composition-api'
export default {
  props: {
    id: String
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const active = ref(false)
    const showExplanation = ref(false)
    const share = ref([])
    const service = root.$feathers.service('checkin/event')

    watch(() => props.id, () => {
      service.get(props.id).then((data) => load(data))
    })

    async function load ({ share: loadShare }) {
      share.value = []
      for (const row of loadShare) {
        if (!('user' in row)) continue
        const data = await root.$feathers.service('directory/people').get(row.user)
        const obj = { user: row.user, role: row.role, name: row.name }
        if (data != null && 'photo' in data) obj.photo = data.photo
        share.value.push(obj)
      }
    }

    const userSearch = ref('')
    const userSearchResults = ref([])
    watch(userSearch, (val) => {
      if (val == null || val === '' || typeof (val) === 'object') return
      // console.log(val)
      let query = {
        $sort: {
          'name.last': 1,
          'name.first': 1
        },
        $limit: 10
      }
      if (val.indexOf(',') >= 0) {
        const [last, first] = val.split(',')
        query['name.last'] = { $regex: last.trim(), $options: 'i' }
        query['name.first'] = { $regex: first.trim(), $options: 'i' }
      } else if (val.indexOf(' ') >= 0) {
        const [first, last] = val.split(' ')
        query['name.first'] = { $regex: first.trim(), $options: 'i' }
        query['name.last'] = { $regex: last.trim(), $options: 'i' }
      } else {
        query.$or = [
          { 'name.first': { $regex: val.trim(), $options: 'i' } },
          { 'name.last': { $regex: val.trim(), $options: 'i' } }
        ]
      }
      query._id = { $nin: [ ...share.value.map(({ user }) => user) ] }
      root.$feathers.service('directory/people').find({ query }).then(({ data }) => {
        userSearchResults.value = data
      })
    })

    const selectedUser = ref(null)
    watch(selectedUser, async (val) => {
      if (val != null && val !== '') {
        const data = await service.patch(props.id, { $push: { share: { user: val._id, name: val.name.last + ', ' + val.name.first, role: 'Scanner', photo: val.photo } } })
        addMessage('User added')
        load(data)
        root.$nextTick(() => {
          userSearch.value = ''
          selectedUser.value = null
        })
      }
    })

    async function removeUser (user) {
      const data = await service.patch(props.id, { $pull: { share: { user } } })
      addMessage('Removal complete')
      load(data)
    }

    async function updateRole (index, role) {
      const patch = { ['share.' + index + '.role']: role }
      const data = await service.patch(props.id, patch)
      addMessage('Role updated')
      load(data)
    }

    const message = ref('')
    const timeout = ref(null)
    function addMessage (text, delay) {
      message.value = text
      if (timeout.value != null) clearTimeout(timeout)
      timeout.value = setTimeout(() => { message.value = '' }, delay || 5000)
    }

    return {
      user,
      active,
      showExplanation,
      share,
      userSearch,
      userSearchResults,
      selectedUser,
      removeUser,
      updateRole,
      message
    }
  }
}
</script>
